import React, { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { CheckAuthorization } from "./services/authorization.service";
import {
  CheckAuthentication,
  performOpenamLogin,
} from "./services/authentication.service";
import Team from "./components/teammanagement/Teams";
import Home from "./components/home";
import RequiredAuth from "./components/RequiredAuth";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import {
  Header,
  HeaderOptionItem,
  Avatar,
  Footer,
  Loading,
  Modal,
} from "@appkit4/react-components";
import { ConfirmProvider } from "material-ui-confirm";
import UploadSurvey from "./components/survey/uploadsurvey";
import AddUploader from "./components/usermangement/adduploaders";
import TestSurveyList from "./components/survey/testsurveylist";
import Unauthorized from "./components/unauthorized";
import PageNotFound from "./components/PageNotFound";
import PrivacyStatement from "./components/PrivacyStatement";
import SurveyForm from "./components/survey/surveyform";
import PreviewSurvey from "./components/survey/previewsurvey";
import ThankYouSurvey from "./components/survey/thankyousurvey";
import ServiceProblem from "./components/ServiceProblem";
import { getrequest } from "./services/global.service";
import Cookies from "js-cookie";
import axios from "axios";
import image4 from "./assets/images/side.png";
import { postrequest } from "./services/global.service";
import CookiesStatement from "./components/CookiesStatement";
import Terms from "./components/Terms";

var logOutSurveyUrl = `${process.env.REACT_APP_API_BASE_URL}/api/UserManagement/LogOut?userEmail=`;

var getUserDetailsURL = `${process.env.REACT_APP_API_BASE_URL}/api/UserManagement/GetUserDetail?userGUID=`;

const baseURL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedUser, setLoggedUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [secureLocalStorage.getItem("OPENAMSESSION")]);

  const addUser = () => {
    const payloadData = {
      id: "",
      guid: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).guid,
      userDetails: {
        firstName: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).given_name,
        lastName: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).family_name,
        email: JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail,
        roleId: "Admin",
      },
    };

    axios
      .post(`${baseURL}/api/NewUser/CreateNewUser`, payloadData)
      .then(() => {
        getUser();
      })
      .catch((error) => {
        navigate("/service-problem");
      });
  };

  const getUser = () => {
    getrequest(
      getUserDetailsURL + JSON.parse(Cookies.get("OPENAMUSERDETAIL")).guid
    ).then((response) => {
      if (response.response?.status === 401) {
        addUser();
      } else {
        if (response.data.data !== "[]") {
          const userinfo = JSON.parse(response.data.data)[0].userDetails;
          setLoggedUser(JSON.parse(response.data.data)[0].userDetails);
          Cookies.set("userdetails", JSON.stringify(userinfo), {
            SameSite: "Strict",
            secure: true,
          });
          setIsLoading(false);
          const pathname = Cookies.get("path");
          if (pathname?.startsWith("/survey/")) {
            navigate(pathname, { replace: true });
          } else {
            navigate(location.pathname, { replace: true });
          }
        }
      }
    });
  };

  const loadData = async () => {
    const valid = await CheckAuthentication();
    if (valid) {
      console.log("checked and authentication is valid")
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/Auth/GenerateAccessToken`,
          {
            headers: {
              Authorization:
                "Bearer " +
                JSON.parse(secureLocalStorage.getItem("OPENAMSESSION"))
                  .access_token,
            },
          }
        )
        .then(async (response) => {
          console.log("checked and response is valid")
          secureLocalStorage.setItem("TOKEN", response.data.token);
          secureLocalStorage.setItem(
            "REFRESHTOKEN",
            response.data.refreshToken
          );

          await CheckAuthorization().then(async (response) => {
            if (response.mail.includes("@pwc.com")) {
              getUser();
            } else {
              setIsLoading(false);
              const pathname = Cookies.get("path");
              if (pathname !== undefined) {
                navigate(pathname, { replace: true });
              } else {
                navigate("/unauthorised", { replace: true });
              }
            }
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            console.log("error below");
            console.log(err)
            performOpenamLogin();
            
            
          }
        });
    }
  };

  const logOut = () => {
    if (Cookies.get("OPENAMUSERDETAIL")) {
      postrequest(
        logOutSurveyUrl + JSON.parse(Cookies.get("OPENAMUSERDETAIL")).mail
      ).then((response) => {
        if (response.data.result) {
          Cookies.remove("OPENAMSESSION");
          Cookies.remove("userdetails");
          Cookies.remove("path");
          Cookies.remove("TOKEN");
          Cookies.remove("REFRESHTOKEN");
          Cookies.remove("OPENAMUSERDETAIL");
          Cookies.remove("selectedTeam");
          secureLocalStorage.clear();
          window.location.href =
            "https://login-stg.pwc.com:443/openam/UI/Logout";
        }
      });
    } else {
      window.location.href = "https://login-stg.pwc.com:443/openam/UI/Logout";
    }
  }

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );

  const handleClick = () => {
    navigate("/teams");
  }

  const getInitials = (name) => {
    const names = name.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <ConfirmProvider>
      <div className="container">
        <div className="row">
          <Header
            type="solid"
            titleTemplate={() => "Questionnaire Cloud"}
            onClickLogo={() => loggedUser && navigate("/home")}
            optionsTemplate={() => {
              return (
                <>
                  <HeaderOptionItem
                    iconName="help-question-outline"
                    label="Support"
                    onClick={() => setShowSupportModal(true)}
                  ></HeaderOptionItem>
                  {loggedUser?.firstName && (
                    <HeaderOptionItem
                      iconName="partership-outline"
                      label="Teams"
                      onClick={handleClick}
                    ></HeaderOptionItem>
                  )}
                  <HeaderOptionItem
                    iconName="log-out-outline"
                    label="Logout"
                    onClick={logOut}
                  ></HeaderOptionItem>
                </>
              );
            }}
            userTemplate={() => (
              <div data-theme="orange">
                <Avatar
                  label={
                    loggedUser?.firstName ? (
                      getInitials(
                        loggedUser?.firstName + " " + loggedUser?.lastName
                      )
                    ) : (
                      <span className="Appkit4-icon icon-person-outline"></span>
                    )
                  }
                  role="button"
                  disabled={true}
                ></Avatar>
              </div>
            )}
          />
        </div>
        <div className="ap-container">
          <div className="row">
            <div className="col-6 banner-background">
              <h1 className="title">
                Hello {loggedUser?.firstName ? loggedUser?.firstName : "there"}.
                Welcome to Questionnaire Cloud!
              </h1>
              <p style={{ color: "white" }}>
                {loggedUser?.firstName
                  ? "PwC’s survey tool to create forms and surveys with varied question types."
                  : "PwC’s own survey tool for gathering data."}
              </p>
            </div>
            <div className="col-6 banner-image">
              <img className="banner" src={image4} alt="banner" />
            </div>
          </div>
        </div>
        <div className="ap-container">
          <div className="row mt-md-5">
            <Routes>
              {/* protected routes  */}

              <Route
                element={
                  <RequiredAuth allowRoles={["Content Creator", "Admin"]} />
                }
              >
                <Route path="upload" element={<UploadSurvey />} />
              </Route>
              <Route element={<RequiredAuth allowRoles={["Admin"]} />}>
                <Route path="adduploaders" element={<AddUploader />} />
              </Route>
              <Route
                element={
                  <RequiredAuth
                    allowRoles={["Admin", "Content Creator", "Data Extractor"]}
                  />
                }
              >
                <Route path="surveylist" element={<TestSurveyList />} />
              </Route>
              <Route
                element={
                  <RequiredAuth allowRoles={["Admin", "Content Creator"]} />
                }
              >
                <Route
                  path="preview-survey/:surveyId"
                  element={<PreviewSurvey />}
                />
              </Route>
              <Route
                element={
                  <RequiredAuth
                    allowRoles={["Admin", "Content Creator", "Data Extractor"]}
                  />
                }
              >
                <Route path="teams" element={<Team />} />
              </Route>
              <Route
                element={
                  <RequiredAuth
                    allowRoles={["Admin", "Content Creator", "Data Extractor"]}
                  />
                }
              >
                <Route path="home" element={<Home />} />
              </Route>
              <Route
                element={
                  <RequiredAuth
                    allowRoles={["Admin", "Content Creator", "Data Extractor"]}
                  />
                }
              >
                <Route path="/" element={<Home />} />
              </Route>
              <Route path="survey/:surveyId" element={<SurveyForm />} />
              <Route path="form-submitted" element={<ThankYouSurvey />} />
              <Route path="unauthorised" element={<Unauthorized />} />
              <Route path="/*" element={<PageNotFound />} />
              <Route path="/privacy-statement" element={<PrivacyStatement />} />
              <Route path="/cookies-statement" element={<CookiesStatement />} />
              <Route path="/service-problem" element={<ServiceProblem />} />
              <Route path="/terms-of-use" element={<Terms />} />
            </Routes>
          </div>
        </div>
        <Footer
          className="footer-padding"
          content={`© ${new Date().getFullYear()} PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a className='ap-link' target="_blank" rel="noopener noreferrer" href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further 
details.`}
          type="text"
        />

        <Modal
          visible={showSupportModal}
          title="Support"
          onCancel={() => {
            setShowSupportModal(false);
          }}
          modalStyle={{ width: "33.75rem" }}
          footerStyle={{
            paddingTop: "8px",
            marginTop: "-8px",
            minHeight: "64px",
          }}
          header={""}
          footer={""}
          bodyStyle={{ minHeight: "92px" }}
        >
          {Cookies.get("OPENAMUSERDETAIL") &&
          JSON.parse(Cookies.get("OPENAMUSERDETAIL"))?.mail.includes(
            "@pwc.com"
          ) ? (
            <p>
              Please submit a support ticket to UK-IT-QUESTIONNAIRE-CLOUD-L2
              SUPPORT group in{" "}
              <a
                href="https://wwwpwcnetwork.pwc.myshn.net/pwc?shn-direct"
                target="_blank"
                rel="noopener noreferrer"
                className="ap-link"
              >
                ServiceNow
              </a>
              .
            </p>
          ) : (
            <p>For support, please reach out to your client team</p>
          )}
        </Modal>
      </div>
    </ConfirmProvider>
  );
}

export default App;
